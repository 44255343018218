.clear-filter[filter-color="black"] {
  @include linear-gradient(rgba($black-color,.20), rgba(0, 0, 0, 0.6));
}
.clear-filter[filter-color="blue"] {
  @include linear-gradient(rgba($black-color,.20), rgba(3, 161, 224, 0.6));
}
[data-background-color="blue"]{
    background-color: $info-color;
}
.section-nucleo-icons .icons-container i:nth-child(6) {
  color: $info-color;
}
.page-header{
  &.page-header-small{
    min-height: 60vh;
    max-height: 640px;
  }
}
.section-products {
  padding: 70px 0;
  position: relative;
  background-color: #262626;
}
.section-aboutus {
  padding: 70px 0;
  position: relative;
  // background-color: #A117F2;
  // background-image: url("../../../img/bg5.jpg");
  // background-image: url("../../../img/bg5mono.png");
  // background-image: url("../../../img/network_bg.jpg");
  background-color: #8612c9;
}
.section-team {
  // background-color: #262626;
  background-color: #ffffff;
}
.team {
  padding-top: 50px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}